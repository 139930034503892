import Vue from 'vue';
import router from '@/plugins/vue-router';
import * as Sentry from '@sentry/vue';

if (['production'].includes(process.env.NODE_ENV) && (process.env.VUE_APP_T8H_ENABLE?.toLowerCase() === 'true')) {
  Sentry.init({
    Vue,
    dsn: 'https://037d345b47636b7a6a18e5de8eb72600@err.t8h.io/16',
    integrations: [
      Sentry.browserTracingIntegration(router),
    ],
    tracesSampleRate: 1,
  });
}
